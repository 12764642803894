import React from 'react';
import styled from 'styled-components';
import SanityImage  from "gatsby-plugin-sanity-image";
import theme from "styles/style.js";
import ScrollContainer from 'react-indiana-drag-scroll';



export const SectionPhotoGallery = styled.section`
  display: grid;
  padding-top: 3em;
  grid-template-columns:  25% 25% 25% 25%;
  column-gap: 1em;
  row-gap: 1em;
  background-color: ${theme.backgroundDarkColor};
  img {
    //filter: grayscale(1);
  }
  @media (max-width: ${theme.device} ){
    grid-template-columns: 50% 50%;
  }
`
export const DivPhoto = styled.div`
    position: absolute;
    width: 80vw;
    height: 80vh;
    margin-left: 10vw;
    border: red 1px solid;
`
export const SectionSlider = styled.section`
  height: 100%;
  //min-height: 550px;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  flex-flow: nowrap;
`
export const ArticleSlide = styled.article`
  display: block;
  margin: 0 0 0 40px;
  max-height: 90vh;
  min-width: 420px;
  /* flex-basis: 500px */
  flex:none;
  transform: scale(1);
  box-sizing: border-box;
  padding: 0;
  text-transform: uppercase;
  transition:1s;
  `

export const Photos = ( {photos} ) => {

    let photos_render = [];


    photos.forEach((photo, index) => (
        photos_render.push(<div className={"sliderContainer"} key={index} >
            <ArticleSlide >
                {/* <SanityImage  {...photo} width={photo.asset.gatsbyImageData.width} height={photo.asset.gatsbyImageData.height} alt={"photo"} */}
                <SanityImage  {...photo} width={434} alt={"photo"}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }} />
            </ArticleSlide>
        </div>)
    ))
    return (
        <>
        <ScrollContainer  className="scroll-container">
                 <SectionSlider>
                     {photos_render}
                 </SectionSlider>
        </ScrollContainer>
        </>
    );
};

export default Photos;