import * as React from "react";
import Helmet from "react-helmet";

import {
  logo
} from "../../../data/config";
import {PropsWithChildren} from "react";

interface PropsMarkupWork extends PropsWithChildren<any>{
  name: string;
  description: string;
  image: string;
  createdAt: string;
}

const WorkMarkupSchema: React.FC<PropsMarkupWork> = (props: PropsMarkupWork) => {
  const markup = `{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "${props.name}",
  "description": "${props.description}",
  "thumbnailUrl": "${props.image}",
  "uploadDate": "${props.createdAt}",  
  "publisher": {
    "@type": "Organization",
    "name": "Obtura Audiovisual",
    "logo": {
      "@type": "ImageObject",
      "url": "${logo}",
      "width": 30,
      "height": 30
    }
  }
}`;

  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default WorkMarkupSchema;
