import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import HeroPortFolio from "components/portafolio/hero/hero-portfolio";
import Contacto from "components/common/contacto/Contacto";
import BlockContent from "@sanity/block-content-to-react";
import LazyYoutube from "components/common/iframe/lazyYoutube";
import Reviews from "components/common/reviews/reviews";
import Contact from "components/common/form";
import Breadcrumb from "../components/common/breadcrumbs/Breadcrumb";
import Similars from "../components/portafolio/gallery/Similars";
import Photos from "../components/portafolio/gallery/Photos";
import {graphql} from "gatsby";
import theme from "styles/style";
import GoProject from "../components/ui/atoms/GoProject";
import {SEO} from 'components/common/SEO';
import WorkMarkupSchema from '../components/common/SEO/WorkMarkupSchema';


export default function Project({pageContext: {node, ne, eq}, data}) {

    const start = createRef(null);
    const end = createRef(null);
    const block_1 = createRef(null);
    const block_2 = createRef(null);

    const adjust = {
        start: {left: 50, color: `${theme.orange}`, size: 0, center: false, animation: false},
        block_1: {left: 0, color: `${theme.blue}`, size: 200, center: false, animation: true},
        block_2: {left: 50, color: `${theme.green}`, size: 150, center: false, animation: true}
    }
    return (
        <>
            <SEO
                title={`${node.title} | Obtura`}
                description={node.description[0].children[0].text}
                robots='index, follow'
                canonical={`nuestro-trabajo/${node.slug.current}`}
            />
            <WorkMarkupSchema name={node.title} description={node.description[0].children[0].text}
                              createdAt={node._createdAt}
                              image={node.mainImage.asset.gatsbyImageData.images.fallback.src}/>
            <Layout start={start} block_1={block_1} block_2={block_2} end={end} adjust={adjust}>
                <Breadcrumb to={"/nuestro-trabajo/"}> Portfolio </Breadcrumb>
                <div className={"circle__dawn"}>
                    <HeroPortFolio title={node.title} to={`#${node.codeVideo}`}>
                        <BlockContent blocks={node._rawDescription}/>
                    </HeroPortFolio>
                </div>
                <div className={"container"} ref={start}></div>
                {node.codeVideo &&
                    <div className={"container padding-xl"} id={node.codeVideo}>
                        <LazyYoutube title={node.title} src={node.codeVideo}/>
                    </div>
                }
                {node.is_photo &&
                    <>
                        <Photos photos={node.galeria}/>
                    </>
                }

                <div className={"container padding-xl"} ref={block_1}>
                    <Similars works={data.allSanityPortfolio.edges}/>
                    <GoProject/>
                </div>
                <div className={"container padding-xl"}>
                    <h2 className={"h1 text-center putDot"} ref={block_2}>
                        TAMBIÉN PODEMOS <br/> HACER TU VIDEO
                    </h2>
                </div>
                <div className={"container"}>
                    <div className={"grid-x2"}>
                        <div>
                            <Reviews/>
                        </div>
                        <div>
                            <Contact/>
                        </div>
                    </div>
                </div>
                <div className={"container padding-xl"} id={"contactar"} ref={end}>
                    <Contacto/>
                </div>
            </Layout>
        </>
    )
}

// query SimilarsQuery( $ne: String = "", $eq: String = "-1e7a7f57-bba8-5239-a5f1-69eb6080e6f6") {
//     allSanityPortfolio(-bdda616b-c075-5f9e-8f1a-f8048be06531
//         filter: {id: {ne: $ne}, categories: {elemMatch: {id: {eq: $eq}}}}
// ){


export const query = () => {
    graphql`
        query SimilarsQuery(  $ne: String = "", $in: [String] = ["-bdda616b-c075-5f9e-8f1a-f8048be06531"]){
            allSanityPortfolio(
                filter: {id: {ne: $ne}, categories: {elemMatch: {id: {in: $in}}}}
            ) {
                edges {
                    node {
                        _createdAt
                        codeVideo
                        title
                        is_photo
                        galeria {
                            ... ImageWithPreview
                        }
                        mainImage {
                            ... ImageWithPreview
                        }
                        publishedAt
                        slug {
                            current
                        }
                        categories {
                            title
                            id
                        }
                    }
                }
            }
        }
    `
};