import React, {createRef, useState, Suspense} from "react";
import styled from "styled-components";


export const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
`


const LazyYoutube = ({src, title}) => {
    const isSSR = typeof window === "undefined"; // hack to ssr and lazy
    const [showVideo, setShowVideo] = useState(false);
    const [launchVideo, setLaunchVideo] = useState(false);
    const container = createRef();

    const finishLoading = () => {
        setShowVideo(true);
    }



    return (

            <div className="embed-iframe" ref={container}>
                {!isSSR && launchVideo && (
                    <Suspense fallback={null}>
                        <Iframe id={"player"} className={showVideo ? 'visibility is-z2' : 'noVisibility'} width="560" height="315"
                                src={"https://www.youtube.com/embed/" + src + "?controls=1"}
                                title={title} frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                scrolling="no"
                                onLoad={finishLoading}
                                allowFullScreen>
                        </Iframe>
                    </Suspense>
                )}
                <picture className="is-z1" onClick={() => {
                    setLaunchVideo(true)
                }} onMouseOver={() => {
                    setLaunchVideo(true)
                }}>
                    <source media="(max-width:650px)" srcSet={"https://img.youtube.com/vi/" + src + "/mqdefault.jpg"}/>
                    <img src={"https://img.youtube.com/vi/" + src + "/maxresdefault.jpg"} alt={title} />
                </picture>
            </div>
    );

}

export default LazyYoutube;