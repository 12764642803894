import React, {useLayoutEffect} from 'react';
import styled from 'styled-components';
import Link from "gatsby-link";
import SanityImage  from "gatsby-plugin-sanity-image";
import theme from "styles/style.js";

export const DivContainer = styled.div`
  h2{
    margin:0;
  }
`

export const SectionGallery = styled.section`
  display: grid;
  padding-top: 1em;
  grid-template-columns: 50% 50%;
  column-gap: 1em;
  row-gap: 1em;
  .h2 {
    margin-top: -0.5em;
    font-size: ${theme.fsTitleS};
  }
  img {
    //filter: grayscale(1);
  }
  @media (max-width: ${theme.device} ){
    grid-template-columns: 100%;
  }
`

export const Similars = ({works} ) => {

    const LengthWorks = works.length;
    let cursor = null;

    function biggerCircle() {
        if(cursor) { cursor.classList.add('circle--bigger'); }
    }
    function backCircle(){
        if(cursor) {  cursor.classList.remove('circle--bigger');}
    }
    useLayoutEffect(()=>{
        cursor = document.getElementById("mainCursor");
    }, [])

    if(LengthWorks < 1) {
        return true;
    }

    let toSelectArray = [];

    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
    for(let i = 0; i < LengthWorks; i++ ){
        toSelectArray.push(i);
    }

    const works_render = []
    const works_shuffle = []

    if(LengthWorks <= 2){
        works.forEach((work) => {works_shuffle.push(work)} )

    } else {
        toSelectArray = shuffleArray(toSelectArray);
        for (let i = 0; i < 2 ; i++){
            works_shuffle.push(works[i]);
        }
    }







    works_shuffle.forEach((work, index) => (
        works_render.push(<div key={index}  onMouseOver={biggerCircle} onMouseLeave={backCircle} >
            <Link onClick={backCircle}   to={`/nuestro-trabajo/${work.node.slug.current}/`} title={work.node.title}>
                <SanityImage  {...work.node.mainImage} width={500} height={300} alt={work.node.title}
                              style={{
                                  // width: "100%",
                                  // height: "100%",
                                  // objectFit: "cover",
                              }} />
                <h3 className={"h2"}>{work.node.title}</h3>
            </Link>
        </div>)
    ))
    return (
        <DivContainer>
            <h2 className={"h1"} > Trabajos similares</h2>
            <SectionGallery>
                {works_render}
            </SectionGallery>
        </DivContainer>
    );
};

export default Similars;